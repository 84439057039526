//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard, XDown } from '@/components'
import { Empty } from 'ant-design-vue'
import { getOrgTree } from '@/api/modular/system/orgManage'
import { getUserPage, sysUserDelete, sysUserChangeStatus, sysUserResetPwd, sysUserExport } from '@/api/modular/system/userManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import addForm from './addForm'
import editForm from './editForm'
import userRoleForm from './userRoleForm'
import userOrgForm from './userOrgForm'
export default {
  components: {
    XDown,
    XCard,
    STable,
    addForm,
    editForm,
    userRoleForm,
    userOrgForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '賬號',
          dataIndex: 'account'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '性別',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' }
        }, {
          title: '手機',
          dataIndex: 'phone'
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return getUserPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        key: 'id'
      },
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      }
    }
  },
  created () {
    /**
     * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
     */
    getOrgTree(Object.assign(this.queryParam)).then(res => {
      this.treeLoading = false
      if (!res.success) {
        return
      }
      this.orgTree = res.data
      for (var item of res.data) {
        // eslint-disable-next-line eqeqeq
        if (item.parentId == 0) {
          this.defaultExpandedKeys.push(item.id)
        }
      }
    })
    this.sysDictTypeDropDown()
    if (this.hasPerm('sysUser:edit') || this.hasPerm('sysUser:resetPwd') || this.hasPerm('sysUser:grantRole') || this.hasPerm('sysUser:grantData') || this.hasPerm('sysUser:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    sexFilter (sex) {
      // eslint-disable-next-line eqeqeq
      const values = this.sexDictTypeDropDown.filter(item => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown (text) {
       sysDictTypeDropDown({ code: 'sex' }).then((res) => {
         this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
    },
    /**
     * 修改用戶狀態
     */
    editUserStatus (code, record) {
      // eslint-disable-next-line no-unused-vars
      const status = 0
      // eslint-disable-next-line eqeqeq
      if (code == 0) {
        this.status = 1
      // eslint-disable-next-line eqeqeq
      } else if (code == 1) {
        this.status = 0
      }
      sysUserChangeStatus({ id: record.id, status: this.status }).then(res => {
        if (res.success) {
          this.$message.success('操作成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('操作失敗：' + res.message)
        }
      })
    },
    /**
     * 重置密碼
     */
    resetPwd (record) {
      sysUserResetPwd({ id: record.id }).then(res => {
        if (res.success) {
          this.$message.success('重置成功')
          // this.$refs.table.refresh()
        } else {
          this.$message.error('重置失敗：' + res.message)
        }
      })
    },
    /**
     * 單個刪除
     */
    singleDelete (record) {
      const param = [{ 'id': record.id }]
      this.sysUserDelete(param)
    },
    /**
     * 批量刪除
     */
    batchDelete () {
      const paramIds = this.selectedRowKeys.map((d) => {
        return { 'id': d }
      })
      this.sysUserDelete(paramIds)
    },
    /**
     * 刪除用戶
     */
    sysUserDelete (param) {
      sysUserDelete(param).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    /**
     * 批量導出
     */
    batchExport () {
      sysUserExport().then((res) => {
        this.$refs.batchExport.downloadfile(res)
      })
    },
    /**
     * 點擊左側機構樹查詢列表
     */
    handleClick (e) {
      this.queryParam = {
        'sysEmpParam.orgId': e.toString()
      }
      this.$refs.table.refresh(true)
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
